<template>
    <o-col-container>
        <div class="flex-column d-flex position-relative">
            <div class="form-check mt-2 ms-2 diff-checkbox">
                <input class="form-check-input" v-model = "showDiff" type="checkbox"  id="defaultCheckDiff">
                <label class="form-check-label" for="defaultCheckDiff">
                    Show diff only
                </label>
            </div>
            <Overlay v-if="diffListLoading"/>
            <div class="list-group diff-list list-group-flush" >
            
                <button v-for="file in diffListArr" class="list-group-item list-group-item-action" :class="getClass(file)" @click="changeSources(file)">
                    {{file.fileName}}
                    <span class="float-end">
                    <span class="badge bg-secondary" v-if="file.modified"> !==</span>
                    <span class="badge bg-secondary" v-if="file.added"> +</span>
                    <span class="badge bg-secondary" v-if="file.removed"> -</span>
                    </span>
                </button>
            </div>
         </div>
        <o-sizer-panel width="85%" id="id_mversionCompare4">
            <div class="row-container ps-2">
                <VersionsToCompare :versionsCompare="versionsCompare" @sourceChange = "sourceChanged" :currentVersion="parseInt(currentVersion)"></VersionsToCompare>
            </div>
            <o-sizer-panel height="50%" id="id_mversionCompare">
                <Overlay v-if="isLoading"/>
                <Compare v-if="source1" :source1 = "source1" :source2 = "source2" class="w-100 pt-2"></Compare>
            </o-sizer-panel>
            
        </o-sizer-panel>
    </o-col-container>
</template>
<script setup lang="ts">
    import {getVersionsCompare} from "o365.modules.VersionsCompare.ts";
    import VersionsToCompare from "o365.vue.components.VersionsToCompare.vue";
    import Compare from 'o365.vue.components.CodeCompare.vue';
    import { Overlay } from 'o365.vue.components.jsx';
    import {ref,computed} from "vue";

   
    const source1 = ref(null);
    const source2 = ref(null);
    const showDiff = ref(true);
    const diffList = ref([]);
    const activeFileName = ref("Html.json")
    const isLoading = ref(true);
    const diffListLoading = ref(true);

    const diffListArr = computed(() => { 
        if(showDiff.value){
            return diffList.value.filter(x=>x.hasChanges)
        }
        return diffList.value;
    })

    const getClass = (pRow)=>{
        if(pRow.fileName ==  activeFileName.value){
            return "active";
        }
        if(pRow.added){
            return "text-success";
        }
        if(pRow.removed){
            return "text-danger";
        }
        if(pRow.modified){
            return "text-warning";
        }
    }
   
    const props = defineProps({
        fileName:{
            type:String,
            required:true
        },
        type:{
            type:String,
            default:"apps"
        },
        currentVersion: {
            type: Number,
            default: 0
        }   
    })

    const versionsCompare =  getVersionsCompare(props.fileName,props.type, props.currentVersion);

    const sourceChanged = () =>{
        isLoading.value = true;
        diffListLoading.value= true;
        versionsCompare.getCurrentAppFiles().then(data=>{
            diffList.value = data;
            const vFound = data.findIndex(x=>x.hasChanges);
            const currentData = vFound > -1?data[vFound]:data[0];

            activeFileName.value = currentData.fileName;
            isLoading.value = false;
            diffListLoading.value= false;
            setSources(currentData);
        })  
    
        
    }

    const changeSources = (pFile) =>{
        activeFileName.value = pFile.fileName;
        setSources(pFile);
        
    }

    const setSources = (pFile)=>{
        source1.value = {fileName:"_src1_"+pFile.fileName,code:pFile.code1};
        source2.value = {fileName:"_src2_" + pFile.fileName,code:pFile.code2};
    }

</script>
<style scoped>
    .diff-checkbox{
        flex:0 0 30px;
    }
    .diff-list{
        overflow-y: auto;
    }
</style>